import React, { useEffect } from 'react'
import axios from 'axios'
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import config from '../../../../../../client/config.client'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import { useEventContext } from '../../../../contexts/EventProvider'
import NavBar from '../../../atoms/NavBar'
import BackButton from '../../../atoms/nav-controls/BackButton'
import {
	chakra,
	Box,
	Container,
	Stack,
	HStack,
	Flex,
	Heading,
	Text,
	Divider,
	Alert,
	AlertIcon,
	Spinner,
} from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import Gallery from '../atoms/Gallery'
import FAQ from '../atoms/FAQ'
import { useStripe } from '@stripe/react-stripe-js'
import { retrieveURL } from '@vidday/utils'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import ProductForm from '../atoms/ProductForm'
import { useRoleContext } from '../../../../contexts/RoleProvider'
import PublicHeader from '../../public/atoms/PublicHeader'
import PublicFooter from '../../public/atoms/PublicFooter'
import FooterBase from '../../../molecules/FooterBase'
import useStripeResponse from '../../../../hooks/useStripeResponse'
import useUserLocation from '../../../../hooks/useUserLocation'
import KeepsakeCards from '../atoms/KeepsakeCards'
import { restorePublishedMedia } from '../../../../../api/app/media'
// import useBeginCheckoutEvent from '../../../../hooks/useBeginCheckoutEvent'
import getGAClientId from '../../../../../utils/getGAClientId'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

const KeepsakeVideoBook = () => {
	useScrollTopOnMount()
	const { uuid } = useParams()
	const { event, isOwner } = useEventContext()
	const { role } = useRoleContext()

	const navigate = useNavigate()
	const location = useLocation()
	const [searchParams] = useSearchParams()

	const userLocation = useUserLocation()

	const publishedMedia = useSelector((s) => s.event.publishedMedia, shallowEqual)
	const dispatch = useDispatch()
	/** Trigger a restore of published media if it is in glacier and not yet restoring */
	useEffect(() => {
		if (publishedMedia?.inGlacierStorage && !publishedMedia.isRestoring) {
			dispatch(restorePublishedMedia({ id: publishedMedia.id }))
		}
	}, [publishedMedia])

	/** Stripe Form management */
	const stripe = useStripe()

	/** Retrieve stripe statuses generated through search params */
	const stripeResponse = useStripeResponse()

	const handleClose = () =>
		navigate(
			location?.state?.backDestination ||
				(location.pathname.includes('/event')
					? `/event/${uuid}${location.search}`
					: `/e/${uuid}${location.search}`)
		)

	/** Handler to proceed with the payment through stripe */
	const handleSubmit = (data) => {
		if (!stripe && typeof window !== 'undefined' && window) {
			// force reload is stripe sdk is not loaded
			window.location.reload()
		} else {
			/** POST Contribution and retrieve Stripe Checkout session ID */
			const successUrl = `${retrieveURL()}${location.pathname}?stripe_success=true${
				searchParams.size > 0 ? '&' + searchParams.toString() : ''
			}`
			const cancelUrl = `${retrieveURL()}${location.pathname}?stripe_error=true${
				searchParams.size > 0 ? '&' + searchParams.toString() : ''
			}`
			const analyticsClientId = getGAClientId()
			instance
				.post(`/v2/heirloom`, {
					successUrl,
					cancelUrl,
					customerEmail: searchParams.has('customer_email') ? searchParams.get('customer_email') : undefined,
					publishedMediaId: publishedMedia ? publishedMedia.id : undefined,
					quantity: data.quantity,
					coverArt: data.coverArt || 'blank',
					addonGiftBox: data.addonGiftBox || false,
					shippingZone: userLocation?.country === 'US' ? 'domestic' : 'international', // this could eventually come from a modal
					analyticsClientId: analyticsClientId || undefined,
				})
				.then(function (response) {
					let body = response.data
					if (body.success && body.data.checkoutSessionId) {
						// push checkout info to gtm
						// useBeginCheckoutEvent(body.data.checkoutSessionId)
						// redirect to Stripe Checkout
						stripe.redirectToCheckout({ sessionId: body.data.checkoutSessionId }).then((result) => {
							// Handle when redirect fails
							if (result.error) {
								stripeResponse.setError(result.error.message)
							}
						})
					}
				})
				.catch(function (error) {
					if (error.response) {
						stripeResponse.setError(error.response.data.message)
					}
				})
		}
	}

	if (event) {
		return (
			<>
				<NavBar pos={isOwner ? 'absolute' : 'relative'}>
					<HStack spacing={['4%', '1rem']}>
						<BackButton left onClick={handleClose} />
					</HStack>
				</NavBar>

				{role != 'creator' && <PublicHeader />}

				<Container maxW="container.lg" my="2rem">
					<Card
						px={['1rem', '2rem']}
						py={['1rem', '2rem']}
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						borderRadius={['xl']}>
						<Stack w="full" direction={['column', 'row']} spacing="2rem">
							<Flex direction="column" maxW={['512']}>
								<Gallery product="videobook" />
								<Box display={['none', 'block']} maxW="inherit">
									<FAQ product="videobook" />
								</Box>
							</Flex>
							<Flex direction="column" textAlign="left">
								<Heading as="h2" size="lg">
									Video Book Keepsake
								</Heading>

								<HStack mb="1rem">
									<Text fontSize="1.25rem">
										$79.
										<chakra.small top="-6px" position="relative" fontSize="x-small">
											99
										</chakra.small>{' '}
										<chakra.small>USD</chakra.small>
									</Text>
									<Text color="pink.500" fontSize="1.25rem" textDecoration="line-through">
										$100 USD
									</Text>
									{userLocation?.country != 'US' && (
										<Text color="gray.400">
											<small>+ shipping & tax</small>
										</Text>
									)}
								</HStack>
								{userLocation?.country === 'US' && (
									<Text mb="1rem" fontWeight="bold">
										Free shipping in the US 🇺🇸
									</Text>
								)}

								{(stripeResponse.error || stripeResponse.success) && (
									<Alert
										status={stripeResponse.error ? 'error' : 'success'}
										borderRadius="base"
										mb="1rem">
										<AlertIcon />
										<Text>
											{stripeResponse.error
												? stripeResponse.errorMessage
												: stripeResponse.successMessage}
										</Text>
									</Alert>
								)}

								<ProductForm isVideoBook={true} onSubmit={handleSubmit} />

								<Divider />

								<Text my="2rem" color="dark">
									A premium video-playing book that puts your memories into your hands, playing your
									VidDay video when opened.
									<br />
									<br />
									Embrace the modern way of sending a VidDay with our premium 5" HD video book, which
									preserves your messages, memories, and milestones in a unique keepsake.
								</Text>

								<Box display={['block', 'none']} w="full">
									<FAQ product="videobook" />
								</Box>
							</Flex>
						</Stack>
					</Card>

					<KeepsakeCards />

					{role != 'creator' ? (
						<>
							<Divider variant="dashed" my="1rem" />
							<PublicFooter />
						</>
					) : (
						<FooterBase />
					)}
				</Container>
			</>
		)
	}
	return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
}

KeepsakeVideoBook.propTypes = {}

export default KeepsakeVideoBook
